<template>
  <div class="service">
    <div class="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <p class="search">
        <el-select
          v-model="searchForm.state"
          v-if="permissoin.includes('query')"
          clearable
          size="mini"
        >
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="permissoin.includes('query')"
          type="text"
          size="mini"
          clearable
          v-model="searchForm.name"
          placeholder=" 请输入关键字"
        ></el-input>
        <button
          class="btn1"
          v-if="permissoin.includes('query')"
          @click="
            currentPage2 = 1;
            getData();
          "
        >
          <i class="el-icon-search"></i>
          查询
        </button>
        <button
          class="btn2"
          v-if="permissoin.includes('add')"
          @click="
            modal = 1;
            modalState = 0;
          "
        >
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
        <el-table
          :data="tableData"
          style="width: 98%"
          border
          max-height="780px"
        >
          <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">硬件材料</span>
              <span v-if="scope.row.type == 2">软件系统</span>
              <span v-if="scope.row.type == 3">其他服务</span>
              <span v-if="scope.row.type == 4">商务名目</span>
              <span v-if="scope.row.type == 5">组合包</span>
            </template>
          </el-table-column>
          <el-table-column prop="stock" label="库存"> 
            <template slot-scope="scope">
              <span class="colorblue" style="cursor: pointer;" @click="currentProductId=scope.row.id;getDetail()">{{scope.row.stock}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="admonition" label="产品预警数量">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-dropdown trigger="click" @command="handleCommand">
                <el-button type="primary" plain size="small">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    icon="el-icon-edit"
                    v-if="permissoin.includes('edit')"
                    :command="{ num: 2, data: scope.row }"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-delete"
                    v-if="permissoin.includes('del')"
                    :command="{ num: 3, data: scope.row }"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          @size-change="handleSizeChange"
          prev-text="上一页"
          next-text="下一页"
          :page-size="pageSize"
          :page-sizes="[5, 10, 15, 20]"
          layout="total,sizes, prev, pager, next, jumper "
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增</span>
          <span v-if="modalState == 1">修改</span>
          <span
            @click="
              modal = 0;
              form = {};netArrUpdate=[{}]
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <div class="net">
            <p>
              <span class="spaa"><span class="star">* </span>产品类型</span>
              <el-select v-model="form.type" size="mini" placeholder="请选择">
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>产品名称</span>
              <el-input v-model="form.name" size="mini"></el-input>
            </p>
            <p v-show="form.type == 1">
              <span class="spaa"><span class="star">* </span>预警数量</span>
              <el-input v-model="form.admonition" size="mini"></el-input>
            </p>
            <p v-show="form.type == 4">
              <span class="spaa"><span class="star">* </span>到期时间</span>
              <el-input
                v-model="form.periodValidity"
                placeholder="请输入数字 （单位:小时）"
                size="mini"
              ></el-input>
            </p>
            <div v-if="form.type == 5" >
              <div class="text_item">
                <span>*</span>原材料
                <span @click="addFormUpdateItem">
                  <i class="el-icon-plus"></i>
                  增加材料</span
                >
              </div>
              <div class="jstitle" style="margin-left:7px">
                <span>名称</span>
                <span>数量</span>
              </div>
              <div
                class="net1"
                style="margin-left:7px"
                v-for="(item, index) in netArrUpdate"
                :key="index+10"
              >
                <el-select
                clearable filterable
                  v-model="item.id"
                  size="mini"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in option5"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-model="item.amount"
                  placeholder="请输入"
                  size="mini"
                ></el-input>
                <div style="display: flex">
                  <p class="line"></p>
                  <span
                    style="cursor: pointer; position: relative; top: -6px"
                    @click="deletenvritem(index)"
                    v-show="index > 0"
                    ><i class="el-icon-close">删除</i></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              modal = 0;
              form = {};
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>产品库存详情</span>
          <span
            @click="
              indexModal = 0;
              littleForm = {};
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
            <el-table
          :data="tableDatalittle"
          max-height="600px">
          <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage4 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
  <el-table-column prop="deviceNum" label="设备号" :show-overflow-tooltip="true"
              width="160">
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" :show-overflow-tooltip="true"
              width="140">
              <template slot-scope="scope">
                <span class="colorblue" style="cursor: pointer;" @click="indexModal=1">{{scope.row.productName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="供应商" :show-overflow-tooltip="true"
              width="220"></el-table-column>
              <el-table-column prop="inWarehouseBatch" label="入库批次" :show-overflow-tooltip="true"
              width="160">
            </el-table-column>
              <el-table-column prop="orgName" label="设备状态">
              <template slot-scope="scope">
                <span v-if="scope.row.deviceStatus == 0" class="colorgreen">正常</span>
                <span v-if="scope.row.deviceStatus == 1" class="colorred">故障</span>
                <span v-if="scope.row.deviceStatus == 2" class="colorred">报废</span>
                <span v-if="scope.row.deviceStatus == 2">
                    <span v-if="scope.row.scrapOrigin == 0">自然损坏({{scope.row.nickName}})</span>
                <span v-if="scope.row.scrapOrigin == 1">人为损坏({{scope.row.nickName}})</span>
                <span v-if="scope.row.scrapOrigin == 2">遗失({{scope.row.nickName}})</span>
                </span>
              </template>
            </el-table-column>
        </el-table>
        
        </div>
        <el-pagination
          @current-change="handleCurrentChange4"
          :current-page="currentPage4"
          prev-text="上一页"
          next-text="下一页"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total4"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { productGetList, saveProduct, deleteProduct,getAssembleInfo,
deviceStoreGetList } from "@/api/apis.js";
import { timestampToTime } from "@/utils/getTime.js";
export default {
  data() {
    return {
      form: {},
      info: 1,
      indexModal:0,
      timestampToTime,
      currentPage2: 1,
      dialogVisible: false,
      pageSize: 10,
      hide: 1,
      keysArr: [],
      tableDatalittle:[],
      currentPage4:1,
      modalState: 0,
      modal: 0,
      total: 0,
      total4: 0,
      permissoin: [],
      option1: [
        {
          value: 1,
          label: "硬件材料",
        },
        {
          value: 2,
          label: "软件系统",
        },
        {
          value: 3,
          label: "其他服务",
        },
        {
          value: 4,
          label: "商务名目",
        },
        {
          value: 5,
          label: "组合包",
        },
      ],
      tableData: [],
      currentProductId:'',
      searchForm: {},
      ruleForm: {
        pwd: 123456,
        date1: "",
        date2: "",
      },
      netArrUpdate:[{}],
      props: {
        label: "name",
        children: "children",
      },
    };
  },
  methods: {
    getData() {
      let data = {
        name: this.searchForm.name,
        type: this.searchForm.state,
        pageSize: this.pageSize,
        pageNum: this.currentPage2,
      };
      productGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    handleCommand(d) {
      this.form.id = d.data.id;
      if (d.num == 3) {
        this.dialogVisible = true;
      } else if (d.num == 2) {
        
        this.modalState = 1;
        if(d.data.type==5){
          getAssembleInfo({id:d.data.id}).then(res=>{
            this.modal = 1;
              this.form=res.data
              this.netArrUpdate=res.data.paList
          })
        }else{
          this.form = JSON.parse(JSON.stringify(d.data));
          this.modal=1
        }
        
      }
    },
    deletePro() {
      let data = { id: this.form.id };
      deleteProduct(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    onsubmit() {
      if (this.modalState == 0) {
        let data = {};
        data = {
          name: this.form.name,
          type: this.form.type,
          admonition: this.form.admonition,
          periodValidity: this.form.periodValidity,
          assemble:JSON.stringify(this.netArrUpdate),
        };
        saveProduct(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.getData();
            this.modal = 0;
            this.form = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if (this.modalState == 1) {
        let data = {};
        data = {
          name: this.form.name,
          type: this.form.type,
          admonition: this.form.admonition,
          id: this.form.id,
          periodValidity: this.form.periodValidity,
          assemble:JSON.stringify(this.netArrUpdate),
        };
        saveProduct(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.getData();
            this.modal = 0;
            this.ruleForm = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
     addFormUpdateItem(){
      this.$set(this.netArrUpdate,this.netArrUpdate.length,{})
    },
    getDetail(){
      let data = {
        productId: this.currentProductId,
        pageSize: 10,
        pageNum: this.currentPage4,
        status:1
      };
      deviceStoreGetList(data).then(res=>{
        if(res.code==200){
          this.tableDatalittle=res.data.list
          this.indexModal=1
          this.total4=res.data.total-0
        }else{
          this.tableDatalittle=[]
        }
      })
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val;
      this.getDetail();
    },
    deletenvritem(index){
        this.netArrUpdate.splice(index,1);
    },
    handleCurrentChange(v) {
      this.currentPage2 = v;
      this.getData();
    },
    handleSizeChange(v) {
      this.pageSize = v;
      this.getData();
    },
  },
  mounted() {
    //获取权限
    this.permissoin = [];
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    productGetList({ pageSize: 0 }).then((res) => {
      this.option5 = res.data.list.filter(
        (item) => item.type == 1 || item.type == 5
      );
    });
  },
};
</script>

<style lang="less" >
.service {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  
  .index_main {
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding-left: 34px;
        box-sizing: border-box;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .jstitle {
          span {
            display: inline-block;
            margin-right: 20px;
          }
          & > span:nth-child(1) {
            width: 150px;
          }
          & > span:nth-child(2) {
            width: 150px;
          }
        }
        .net {
          margin-top: 20px;
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-input {
            width: 270px;
          }
          .el-input-number {
            .el-input {
              width: 130px;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }
        .net1 {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 150px;
            margin-right: 20px;
            .el-input {
              width: 150px;
            }
          }
          .el-input {
            width: 150px;
            margin-right: 20px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .el-form {
          .el-form-item__label {
            font-size: 12px;
            color: #222328;
          }
          .el-form-item {
            margin-bottom: 10px;
          }
          .el-input {
            width: 270px;
          }
          .el-form-item__error {
            padding-top: 0px;
          }
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
   .indexModal {
    .inner {
      width: 1000px;
      height: 740px;
      .modal_item3{
        height: 600px;
      }
    }
  }
}
</style>