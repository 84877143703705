/*
 * @Author: your name
 * @Date: 2021-03-29 11:48:43
 * @LastEditTime: 2021-03-29 11:48:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhgdksh\src\utils\getTime.js
 */
export function getNowTime() {
    let hours, minutes, seconds;
    let date = new Date();
    hours = date.getHours();
    hours = hours > 9 ? hours : "0" + hours;
    minutes = date.getMinutes();
    minutes = minutes > 9 ? minutes : "0" + minutes;
    seconds = date.getSeconds();
    seconds = seconds > 9 ? seconds : "0" + seconds;
    let time = hours + ":" + minutes + ":" + seconds;
    return time
}
export function areaNameChange(a){
    if(a==undefined||a==''){
      return ''
    }else{
      var index1 = a.indexOf( ' ' );
    var index2 = a.indexOf( ' ', index1 + 1 );
    if(index2>0){
      return a.substring(index2+1,a.length);
    }else{
      return a
    }
    }
    
  }
  export function fmoney(val) {    

    if(val==undefined||val==''){
      return 0
    }else{
      val=val-0
    //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
     var str = val.toFixed(2) + '';
     var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
     var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
     var ret = intSum + dot;
     return ret;
 }
}
export function getNowTime1() {
    var nowDate = new Date();
 var year = nowDate.getFullYear();
 var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
  : nowDate.getMonth() + 1;
 var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
 var dateStr = year + "-" + month + "-" + day;
    return dateStr
}
export function getNowWeek(){
    let vWeek, vWeek_s;
    let date = new Date();
    vWeek = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    vWeek_s = date.getDay();
    let week = vWeek[vWeek_s]
    return week
}
export function timestampToTime(d) {
    if(!d){
        return ''
    }else{
        var date = new Date(d);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return YY + MM + DD +" "+hh + mm + ss;
    }
    
  }
  export function timestampToTime1(d) {
    if(!d){
        return ''
    }else{
        var date = new Date(d);
  var YY = date.getFullYear() + '-';
  var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  return YY + MM + DD
    }
    
  }